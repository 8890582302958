import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleNotch } from '@fortawesome/free-solid-svg-icons';
import React from 'react';

/**
 * Loader Component for loading partials.
 */
export default class Loader extends React.Component {
  render() {
    return (
      <div className="d-flex align-items-center text-secondary justify-content-center">
        <FontAwesomeIcon icon={faCircleNotch} size="4x" spin />
      </div>
    );
  }
}
