import React from 'react';
import { QUIZSURF_API } from '../utils';

interface ResultBoxProps {
  sessionId: string;
}

interface ResultBoxState {
  isCalculated: boolean;
  calculateResult: number;
}
/**
 * Nəticə komponenti
 * Imtahan barəsində ümumi informasiya
 */
class Result extends React.Component<ResultBoxProps> {
  state: ResultBoxState;
  constructor(props: never) {
    super(props);
    this.state = {
      isCalculated: false,
      calculateResult: 0,
    };
    this.calcExamSession = this.calcExamSession.bind(this);
  }
  /**
   * Backend dən imtahan nəticəsinin hesablanması
   */
  async calcExamSession() {
    const sessionId: string = this.props.sessionId;
    // call https://surf.cantyquizservice.dev.ozunoyren.org/session/HEC8V3T/calculate
    const response = await fetch(`${QUIZSURF_API}/session/${sessionId}/calculate`, {
      method: 'GET',
    });
    const data = await response.json();

    this.setState({
      isCalculated: true,
      calculateResult: data.calculateResult,
    });
  }

  componentDidMount() {
    this.calcExamSession();
  }
  render() {
    const { calculateResult, isCalculated } = this.state;
    return (
      <div className="informationPanel">
        <h2>Sınaq imtahanı yekunlaşmışdır</h2>
        <div className="examResult">
          {isCalculated ? (
            <p>
              Sizin nəticə <b>{calculateResult}</b>
            </p>
          ) : (
            <p>Nəticə hesablanır</p>
          )}
        </div>
      </div>
    );
  }
}

export default Result;
