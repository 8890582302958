import React from 'react';
import queryString from 'query-string';
import './App.css';

import QuestionBox from './components/QuestionBox';
import Welcome from './components/Welcome';
import Loader from './components/Loader';
import { QUIZSURF_API } from './utils';

interface SessionState {
  isLoaded: boolean;
  isStarted: boolean;
  sessionId: string;
  responseData?: sessionDataStruct;
}

interface sessionDataStruct {
  isSuccess: string;
  errorMessage: string;
  errorCode: string;
  quizBankName: string;
  numberOfQuestions: string;
}

// eslint-disable-next-line require-jsdoc-except/require-jsdoc
class App extends React.Component {
  state: SessionState;

  constructor(props: never) {
    super(props);

    this.state = {
      isLoaded: false,
      isStarted: false,
      sessionId: '',
    };
    // Эта привязка обязательна для работы `this` в колбэке.
    this.startQuiz = this.startQuiz.bind(this);
  }

  /**
   * imtahana baçla
   */
  startQuiz() {
    this.setState({ isStarted: true });
  }

  /**
   * Hazırlanmış sessiya məlumatlarının gətirilməsi
   */
  async getCreatedSession(sessionId: string) {
    // call https://surf.cantyquizservice.dev.ozunoyren.org/session/I0VUXXF
    const response = await fetch(`${QUIZSURF_API}/session/${sessionId}`, {
      method: 'GET',
    });
    const data = await response.json();

    this.setState({
      isLoaded: true,
      responseData: data as sessionDataStruct,
      sessionId: sessionId,
    });
  }
  componentDidMount() {
    const parsedQuery = queryString.parse(location.search);
    const querySessionId: string = parsedQuery.sessionId as string;
    console.log('sessionId:' + querySessionId);
    this.getCreatedSession(querySessionId);
  }

  render() {
    const { isLoaded, isStarted, responseData } = this.state;
    console.log(responseData);
    console.log(this.state);
    if (!isLoaded) {
      return (
        <div>
          <Loader />
        </div>
      );
    }
    if (!isStarted) {
      if (responseData?.isSuccess) {
        return (
          <div className="App">
            <div className="quiz-container">
              <Welcome
                quizName={responseData.quizBankName}
                overAllQuestionCount={responseData.numberOfQuestions}></Welcome>
              <div id="btnWrapper">
                <button
                  id="submit"
                  className="startButton btnOperations"
                  onClick={this.startQuiz}>
                  İmtahana başla
                </button>
              </div>
            </div>
          </div>
        );
      } else {
        return (
          <div className="App">
            <div className="quiz-container">
              <div>{responseData?.errorMessage}</div>
            </div>
          </div>
        );
      }
    }
    if (responseData?.isSuccess) {
      return (
        <QuestionBox
          sessionId={this.state.sessionId}
          questionNr="1"
          overAllQuestionCount={responseData.numberOfQuestions}></QuestionBox>
      );
    }
  }
}

export default App;
