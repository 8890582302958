import React from 'react';

interface WelcomeBoxProps {
  overAllQuestionCount: string;
  quizName: string;
}
/**
 * Intro komponenti
 * Imtahan barəsində ümumi informasiya
 */
class Welcome extends React.Component<WelcomeBoxProps> {
  constructor(props: never) {
    super(props);
  }

  render() {
    return (
      <div className="informationPanel">
        <p>
          <strong>{this.props.quizName}</strong>
        </p>
        <p>
          Tapşırıq <strong>{this.props.overAllQuestionCount}</strong> sualdan
          ibarətdir.
        </p>
        <br />
        <p>Aşağıda qeyd olunan məqamlara xüsusilə diqqət yetirin:</p>
        <ul>
          <li>Sualların bir və ya bir neçə cavabı ola bilər</li>
          <li>
            Sualı cavabın seçdikdən sonra <strong>Cavabla</strong> düyməsinə tıklayın
          </li>
          <li>
            Sualın cavabını bilmirsinizsə, <strong>Ötür</strong> düyməsinə tıklayın.
            Səhv cavab vermək və sualı ötürmək eyni mənayə gəlir (Səhvlər mənfi bal
            vermir)
          </li>
          <li>
            İmtahan zamanı brauzeri refresh etməyin, bu sınaq imtahanının yenidən
            başlanmasına və ya sınaq imtahanının ləğvinə gətirib çıxaracaq.
          </li>
          <li>
            Bütün suallar üzrə qeydlərinizi apardıqdan sonra sınaq imtahanının
            nəticəsi avtomatik qaydada Sizə bildiriləcək və bu sınaq imtahanın yekunu
            hesab olunur.
          </li>
        </ul>
        <p>Sizə uğurlar arzulayırıq</p>
      </div>
    );
  }
}

export default Welcome;
