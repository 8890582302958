/* eslint-disable no-invalid-this */
import React from 'react';
import Result from './Result';

import * as Marked from 'marked';
import Loader from './Loader';
import { QUIZSURF_API } from '../utils';

interface OptionContentDataStruct {
  optionID: string;
  optionContent: string;
}
interface QuizData {
  quizContent: string;
  quizName: string;
}

interface QuestionBoxDataStruct {
  quizData: QuizData;
  OptionContent: OptionContentDataStruct[];
  isSuccess: boolean;
  isMultiselect: string;
  errorMessage: string;
}

interface QuestionBoxProps {
  sessionId: string;
  questionNr: string;
  overAllQuestionCount: string;
  questionData?: QuestionBoxDataStruct;
}

interface QuestionBoxState {
  isLoaded: boolean;
  currenQuestionNumber: number;
  isSessionOpen: boolean;
  userAnswers: string[];
  data?: QuestionBoxDataStruct;
}

// eslint-disable-next-line require-jsdoc-except/require-jsdoc
class QuestionBox extends React.Component<QuestionBoxProps> {
  state: QuestionBoxState;

  constructor(props: never) {
    super(props);

    this.state = {
      isLoaded: false,
      currenQuestionNumber: 1,
      isSessionOpen: true,
      userAnswers: [],
    };
    // Эта привязка обязательна для работы `this` в колбэке.
    this.onCheckboxChange = this.onCheckboxChange.bind(this);
    this.onRadioChange = this.onRadioChange.bind(this);
    this.skipQuestion = this.skipQuestion.bind(this);
    this.answerQuestion = this.answerQuestion.bind(this);
    this.getQuestionData = this.getQuestionData.bind(this);
  }

  /**
   * Backend dən sualın gətirilməsi
   */
  async getQuestionData(questionNumber: number) {
    const sessionId: string = this.props.sessionId;
    // call https://surf.cantyquizservice.dev.ozunoyren.org/session/0P4NHHX/3
    const response = await fetch(
      `${QUIZSURF_API}/session/${sessionId}/${questionNumber.toString()}`,
      {
        method: 'GET',
      },
    );
    const data = await response.json();

    this.setState({
      isLoaded: true,
      data: data as QuestionBoxDataStruct,
      currenQuestionNumber: questionNumber,
      answerQuestion: [],
    });
  }

  /**
   * Backend dən sualın cavablandırılması
   */
  async postAnswer(questionNumber: number, getNextQuestion: boolean) {
    const sessionId: string = this.props.sessionId;
    const patchParams = {
      optionset: this.state.userAnswers.toString(),
    };
    // call  https://surf.cantyquizservice.dev.ozunoyren.org/session/0P4NHHX/3
    // body
    // {
    //       "optionset":"SDJDL,DFERS"
    //  }
    this.setState({ isLoaded: false });

    const response = await fetch(
      `${QUIZSURF_API}/session/${sessionId}/${questionNumber.toString()}`,
      {
        method: 'PATCH',
        body: JSON.stringify(patchParams),
        headers: {
          'Content-Type': 'application/json',
        },
      },
    );
    const data = await response.json();

    if (data.isSuccess && getNextQuestion) {
      this.getQuestionData(questionNumber + 1);
    }

    if (!getNextQuestion) {
      this.setState({ isSessionOpen: false, isLoaded: true });
    }
  }

  /**
   * Sualın başın burax
   */
  skipQuestion() {
    const overallQuestionCount = this.props
      .overAllQuestionCount as unknown as number;
    if (this.state.currenQuestionNumber < overallQuestionCount) {
      const questionIndex = this.state.currenQuestionNumber + 1;
      this.getQuestionData(questionIndex);
    } else if (this.state.currenQuestionNumber == overallQuestionCount) {
      this.setState({ isSessionOpen: false });
    }
  }

  /**
   * Sualın cavablandırılması
   */
  answerQuestion() {
    const overallQuestionCount = this.props
      .overAllQuestionCount as unknown as number;

    if (this.state.currenQuestionNumber <= overallQuestionCount) {
      const questionIndex = this.state.currenQuestionNumber;
      let getNextQuestion = true;
      if (this.state.currenQuestionNumber == overallQuestionCount)
        getNextQuestion = false;
      console.log(this.state.userAnswers);
      this.postAnswer(questionIndex, getNextQuestion);
    }
    this.unCheck();
  }

  componentDidMount() {
    this.getQuestionData(this.state.currenQuestionNumber);
  }

  onRadioChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const isChecked = event.target.checked;
    if (isChecked) {
      // eslint-disable-next-line no-invalid-this
      this.setState({
        userAnswers: [event.target.id],
      });
    }
  };

  onCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const isChecked = event.target.checked;
    if (isChecked) {
      // eslint-disable-next-line no-invalid-this
      this.setState({
        userAnswers: [...this.state.userAnswers, event.target.id],
      });
    } else {
      const index = this.state.userAnswers.indexOf(event.target.id);
      this.state.userAnswers.splice(index, 1);
      this.setState({ userAnswers: this.state.userAnswers });
    }
  };
  /**
   * checkboxların təmizlənməsi
   */
  unCheck() {
    Array.from(document.querySelectorAll('input')).forEach(
      (input) => (input.checked = false),
    );
    this.setState({
      userAnswers: [],
    });
  }
  render() {
    const { data, isLoaded, currenQuestionNumber } = this.state;

    if (isLoaded) {
      const questionBoxItem = data as QuestionBoxDataStruct;
      const questionOptions = questionBoxItem.OptionContent;
      return (
        <div className="quiz-container" id="quiz">
          {this.state.isSessionOpen ? (
            <>
              <div className="quiz-paginator">
                Sual: {currenQuestionNumber}/{this.props.overAllQuestionCount}
              </div>
              <div className="quiz-header">
                <h2 id="question">{questionBoxItem?.quizData.quizName}</h2>
                <h3
                  id="questionContent"
                  dangerouslySetInnerHTML={{
                    __html: Marked.marked.parse(
                      questionBoxItem?.quizData.quizContent,
                    ),
                  }}></h3>
                <ul>
                  {questionOptions.map(
                    (item: OptionContentDataStruct, optionIndex: number) => (
                      <li key={optionIndex}>
                        {data?.isMultiselect ? (
                          <input
                            type="checkbox"
                            name="answer"
                            id={item.optionID}
                            className="answer"
                            onChange={this.onCheckboxChange}
                          />
                        ) : (
                          <input
                            type="radio"
                            name="answer"
                            id={item.optionID}
                            className="answer"
                            onChange={this.onRadioChange}
                          />
                        )}

                        <label
                          className="questionOptionLabel"
                          htmlFor={item.optionID}
                          dangerouslySetInnerHTML={{
                            __html: Marked.marked.parse(item.optionContent),
                          }}></label>
                      </li>
                    ),
                  )}
                </ul>
              </div>
              <div className="operationButtons">
                <button
                  id="skip"
                  className="buttonSkip btnOperations"
                  onClick={this.skipQuestion}>
                  Ötür
                </button>
                <button
                  id="submit"
                  className="buttonApply btnOperations"
                  onClick={this.answerQuestion}>
                  Cavabla
                </button>
              </div>
            </>
          ) : (
            <Result sessionId={this.props.sessionId}></Result>
          )}
        </div>
      );
    } else {
      return (
        <div className="sidebar-container">
          <Loader />
        </div>
      );
    }
  }
}

export default QuestionBox;
